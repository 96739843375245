import Swiper from 'swiper';

$(document).ready(function() {

	let blockSlideshows = $('.block-slideshow');

	if(blockSlideshows.length) {

		blockSlideshows.each(function() {

			let id = $(this).attr('id');

			let spaceBetween = this.getAttribute('data-swiper-space-between');
			let hasFraction = this.getAttribute('data-swiper-has-fraction');
			let autoplay = this.getAttribute('data-swiper-enable-autoplay');

			let slidesPerViewXl = this.getAttribute('data-swiper-slides-per-view-xl');
			let slidesPerViewLg = this.getAttribute('data-swiper-slides-per-view-lg');
			let slidesPerViewMd = this.getAttribute('data-swiper-slides-per-view-md');
			let slidesPerViewSm = this.getAttribute('data-swiper-slides-per-view-sm');
			let slidesPerViewXs = this.getAttribute('data-swiper-slides-per-view-xs');

			let blockSwiper = new Swiper('#' + id, { // eslint-disable-line

				loop: false,
				speed: 500,
				spaceBetween: spaceBetween ? parseInt(spaceBetween, 10) : 0,
				slidesPerView: slidesPerViewXl ? parseInt(slidesPerViewXl, 10) : 3,
				watchOverflow: true,
				autoplay: autoplay ? { delay: parseInt(autoplay, 10), disableOnInteraction: false } : false,
				simulateTouch: false,
				breakpoints: {
					1200: {
						slidesPerView: slidesPerViewLg ? parseInt(slidesPerViewLg, 10) : 3
					},
					992: {
						slidesPerView: slidesPerViewMd ? parseInt(slidesPerViewMd, 10) : 3
					},
					768: {
						slidesPerView: slidesPerViewSm ? parseInt(slidesPerViewSm, 10) : 1
					},
					576: {
						slidesPerView: slidesPerViewXs ? parseInt(slidesPerViewXs, 10) : 1
					}
				},

				pagination: {
					el: '#' + id + '-swiper-pagination',
					type: hasFraction === 'true' ? 'fraction' : 'bullets',
					clickable: true
				},
				navigation: {
					nextEl: '#' + id + '-next',
					prevEl: '#' + id + '-prev'
				}

			});

		});

	}



	let textSliders = $('.text-slider');

	if(textSliders.length) {

		textSliders.each(function() {

			let id = $(this).attr('id');

			let autoplay = this.getAttribute('data-swiper-enable-autoplay');

			let textSwiper = new Swiper('#' + id, { // eslint-disable-line
				loop: true,
				speed: 500,
				effect: 'fade',
				spaceBetween: 0,
				slidesPerView: 1,
				watchOverflow: true,
				autoplay: autoplay ? { delay: parseInt(autoplay, 10), disableOnInteraction: true } : false,
				simulateTouch: false,
			});

		});

	}



	let imageVideoSlideshows = $('.image-video-slideshow.image-video-slideshow--active');

	if(imageVideoSlideshows.length) {

		imageVideoSlideshows.each(function() {

			let id = $(this).attr('id');

			let autoplay = this.getAttribute('data-swiper-enable-autoplay');

			let imageVideoSwiper = new Swiper('#' + id, { // eslint-disable-line

				loop: true,
				speed: 500,
				spaceBetween: 0,
				slidesPerView: 'auto',
				centeredSlides: false,
				autoplay: autoplay ? { delay: parseInt(autoplay, 10), disableOnInteraction: false } : false,
				simulateTouch: false,

				pagination: {
					el: '#' + id + '-swiper-pagination',
					type: 'bullets',
					clickable: true
				},
				navigation: {
					nextEl: '#' + id + '-next',
					prevEl: '#' + id + '-prev'
				}

			});

			let slideshow = $('#' + id);
			let elem = $(slideshow).find('.module-image-video-slideshow__progress-bar');
			let interval = null;
			let autoplayTime = autoplay / 100;
			let width = 1;

			if(elem.length > 0) {

				imageVideoSwiper.on('slideChange', () => { move(id); });

				move();

			}

			function move() {

				clearInterval(interval);

				width = 1;
				interval = setInterval(frame, autoplayTime);

				function frame() {
					width++;
					$(elem).css('width', width + '%');
				}

			}

		});

	}



	let imageVideoSameHeightSlideshows = $('.image-video-slideshow-same-height.image-video-slideshow-same-height--active');

	if(imageVideoSameHeightSlideshows.length) {

		imageVideoSameHeightSlideshows.each(function() {

			let id = $(this).attr('id');

			let hasFraction = this.getAttribute('data-swiper-has-fraction');
			let autoplay = this.getAttribute('data-swiper-enable-autoplay');

			let imageGallerySwiper = new Swiper('#' + id, { // eslint-disable-line

				loop: true,
				speed: 500,
				spaceBetween: 100,
				slidesPerView: 'auto',
				centeredSlides: true,
				autoplay: autoplay ? { delay: parseInt(autoplay, 10), disableOnInteraction: false } : false,
				simulateTouch: false,
				breakpoints: {
					1200: {
						spaceBetween: 80,
					},
					992: {
						spaceBetween: 50,
					},
					768: {
						spaceBetween: 30,
					},
					576: {
						spaceBetween: 15,
					}
				},

				pagination: {
					el: '#' + id + '-swiper-pagination',
					type: hasFraction === 'true' ? 'fraction' : 'bullets',
					clickable: true
				},
				navigation: {
					nextEl: '#' + id + '-next',
					prevEl: '#' + id + '-prev'
				}

			});

		});

	}



	let iconSlideshowsFixed = $('.icon-slideshow.icon-slideshow--active');

	if(iconSlideshowsFixed.length) {

		iconSlideshowsFixed.each(function() {

			let id = $(this).attr('id');

			let spaceBetween = this.getAttribute('data-swiper-space-between');
			let hasFraction = this.getAttribute('data-swiper-has-fraction');
			let autoplay = this.getAttribute('data-swiper-enable-autoplay');

			let iconSlideshowsSwiper = new Swiper('#' + id, { // eslint-disable-line

				loop: true,
				speed: 500,
				spaceBetween: spaceBetween ? parseInt(spaceBetween, 10) : 15,
				slidesPerView: 'auto',
				centeredSlides: true,
				autoplay: autoplay ? { delay: parseInt(autoplay, 10), disableOnInteraction: false } : false,
				simulateTouch: false,

				pagination: {
					el: '#' + id + '-swiper-pagination',
					type: hasFraction === 'true' ? 'fraction' : 'bullets',
					clickable: true
				},
				navigation: {
					nextEl: '#' + id + '-next',
					prevEl: '#' + id + '-prev'
				}

			});

		});

	}



});
